import React from "react";
import styles from "./css/skill.module.css"

const Skill = ({Title, Start, End}) => {
    console.log(End)
    const calcTime = () => {
        let start = new Date(Start);
        let end = End === undefined ? new Date() : new Date(End);
        var diff = end.getTime() - start.getTime();
        var yeardiff = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));  
        var monthdiff = Math.floor(diff / (1000 * 60 * 60 * 24 * 365 / 12) - (yeardiff * 12));  
        return yeardiff + " Years, " + monthdiff + " Months";
    }

    return (
        <div className={styles.skill_block}>
            <span className={styles.skill_block_title}>{Title}</span>
            <span className={styles.skill_block_experience}>{calcTime()}</span>
        </div>
    )
}

export default Skill;