import React from "react";
import styles from './css/skills.module.css';

import Skill from "./components/Skill"

const Skills = () => {
    return(
        <section className={styles.skills} id="skills">
            <h1 className={styles.skills_title}>Skills</h1>
            <div className={styles.skills_container}>
                <div className={styles.skills_list}>
                    <h3 className={styles.skills_list_title}>Languages</h3>
                    <Skill 
                        Title="Javascript"
                        Start={"2016-06-01"}
                    />
                    
                    <Skill 
                        Title="C#"
                        Start={"2017-06-01"}
                        End={"2022-08-01"}
                    />
                    
                    <Skill 
                        Title="GoLang"
                        Start={"2022-04-01"}
                    />
                    <Skill 
                        Title="TypeScript"
                        Start={"2023-09-01"}
                    />
                    <Skill 
                        Title="Java"
                        Start={"2013-06-01"}
                        End={"2016-06-01"}
                    />
                    
                </div>
                <div className={styles.skills_list}>
                    <h3 className={styles.skills_list_title}>Frameworks</h3>
                    <Skill 
                        Title=".NET"
                        Start={"2017-06-01"}
                        End={"2022-08-01"}
                    />
                    <Skill 
                        Title="ReactJS"
                        Start={"2018-06-01"}
                    />
                    <Skill 
                        Title="ReactNative"
                        Start={"2019-06-01"}
                        End={"2022-08-01"}
                    />
                </div>
                <div className={styles.skills_list}>
                    <h3 className={styles.skills_list_title}>Utility</h3>
                    <Skill 
                        Title="CSS3"
                        Start={"2016-06-01"}
                    />
                    <Skill 
                        Title="HTML"
                        Start={"2016-06-01"}
                    />
                    <Skill 
                        Title="Git"
                        Start={"2018-06-01"}
                    />
                    <Skill 
                        Title="JQuery"
                        Start={"2016-06-01"}
                        End={"2018-06-01"}
                    />
                    <Skill 
                        Title="SQL"
                        Start={"2016-06-01"}
                    />
                    <Skill 
                        Title="AWS"
                        Start={"2021-11-01"}
                    />
                
                </div>
            
            </div>
        </section>
    );
}

export default Skills;