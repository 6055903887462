import React from "react";
import "./css/footer.css"

const Footer = () => {
    return(
        <footer>
            <div className="container">
                <a href="https://www.linkedin.com/in/michaelsvajhart/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                <a href="https://gitlab.com/msvajhart" target="_blank" rel="noopener noreferrer">Gitlab</a>
            </div>
        </footer>
    );
}

export default Footer;