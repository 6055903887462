import React from "react"
import styles from './css/projectApple.module.css';

const ProjectApple = () => {

    const [coordinates, setcoordinates] = React.useState({x:50, y:50});
    const boundsMax = 95;
    const boundsMin = 5;

    const getNewPosition = (current) => {
        return (Math.floor(Math.random() * (boundsMax - boundsMin)) + boundsMin);
        
         
    }

    const moveApple = () => {
        let tempX = getNewPosition(coordinates.x)
        let tempY =  getNewPosition(coordinates.y)

        setcoordinates({x: tempX, y:tempY})
    }

    return (
        <>
            <section className={styles.section}>
                <div className={styles.container}>
                    <h1 className={styles.title}>Project Apple</h1>
                    <h6 className={styles.description}>Simply click the apple.</h6>
                    <div className={styles.fence}>
                        <Apple positionX={coordinates.x} positionY={coordinates.y} onClick={moveApple}/>
                    </div>
                    
                </div>
            </section>
           
        </>
    )
};


const Apple = ({positionX = 50, positionY = 50, onClick}) => {
    return (
        <div className={styles.apple} style={{left: positionX + "%", top:positionY + "%"}} onMouseEnter={onClick}>
            <div className={styles.leaf}></div>
            <div className={styles.flesh}></div>
        </div>
    )
}


export default ProjectApple;