import React from "react";
import styles from './css/university.module.css';


const University = ({Degree, School, Location, Time}) => {
    return(
        <div className={styles.university_block}>
            <h2 className={styles.university_degree}>{Degree}</h2>
            <div className={styles.university_school}>
                <span>{School}</span> - <span>{Location}</span>
            </div>
            <div className={styles.university_time}>
                <span>{Time}</span>
            </div>
        </div>
    )
}

export default University;