import React from "react";
import { NavHashLink as Link } from 'react-router-hash-link';
import "./css/nav.css"
const Nav = () => {
    return(
        <nav>
            <Link smooth  to="/#home" className="Nav-Link">Home</Link>
            <Link smooth  to="/#education" className="Nav-Link">Education</Link>
            <Link smooth  to="/#experience" className="Nav-Link">Experience</Link>
            <Link smooth  to="/#skills" className="Nav-Link">Skills</Link>
            <Link smooth  to="/#projects" className="Nav-Link">Projects</Link>
            {/* <Link smooth  to="/#contact" className="Nav-Link">Contact Me</Link> */}
        </nav>
    );
} 

export default Nav;