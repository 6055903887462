import React from "react";
import styles from './css/education.module.css';

import University from "./components/University";

const Education = () => {
    return(
        <section className={styles.education} id="education">
            <h1 className={styles.education_title}>Education</h1>
            <University 
                Degree="Batchlers of Science in Computer Science"
                School="Oklahoma State University"
                Location="Stillwater, OK"
                Time="August 2012 - May 2017"
            />
        </section>
    );

}

export default Education;