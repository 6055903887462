import React from "react";

const Padder = ({height}) => {
    console.log("height", height)
    var divStyle = {
        height: height + "px",
      };
    return(
        <div style={divStyle}></div>
    );
};

export default Padder;