import React from "react";
import Nav from "./Nav";
import "./css/header.css"
const Header = () => {
	return (
		<header id="header">
			<Nav />
		</header>
	);
};
export default Header;