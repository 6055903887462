import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import './App.css';


import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Padder from "./components/general/Padder";

import Home from './containers/Home';

const App = () => {
  const [state, setState] = useState({
    paddingHeight: 0
  })

  useEffect(() => {
    const measureHeader = () => {
      let h = document.getElementById("header").offsetHeight;
      console.log(h)
      setState(s => { 
        return {
          ...s,
        paddingHeight: h
        }
      });
    }
    
    measureHeader();
  }, []);

  

    return (
      <Router>
          <Header/>
          <Switch>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
          <Footer/>
          <Padder height={state.paddingHeight}/>
      </Router>
    );
  }

export default App;
