import React from "react";
import styles from "./css/job.module.css"

const Job = ({Side, Position, Company, Location, Start, End, Description, Skill, Skills, DetialsTitle, Details}) => {
    return(
            <div className={styles["job_block_" + Side]}> 
                <div>
                    <div className={styles.job_block_row}>
                        <h2 className={styles.job_block_position}>{Position}</h2>
                    </div>
                    <div className={styles.job_block_row}>
                        <span className={styles.job_block_company}>{Company}</span> - <span className={styles.job_block_location}>{Location}</span>
                    </div>
                    <div className={styles.job_block_row}>
                        <span className={styles.job_block_timespan}>{Start} - {End}</span>
                    </div>
                    <div className={styles.job_block_row}>
                        <p className={styles.job_block_description}>{Description}</p>
                    </div>
                    {
                        Skills != null &&
                        <div className={styles.job_block_row}>
                            <h3>Skills Used</h3>
                            <p className={styles.job_block_skills}>{Skills}</p>
                        </div>
                    }
                    {
                        Details.length &&
                            <div className={styles.job_block_row}>
                                <h3 className={styles.job_block_details_title}>{DetialsTitle}</h3>
                                <ul className={styles.job_block_details_list}>
                                    {
                                        Details.map((item, key) =>
                                            <li key={key} className={styles.job_block_details_list_item}>{item}</li>
                                        )
                                    }
                                </ul>
                            </div>
                    }
                </div>
            </div>
    )
};

export default Job;