import React, {useEffect} from "react";
import Splash from "../components/general/Splash";
import Education from "../components/resume/Education";
import Experience from "../components/resume/Experience";
import Skills from "../components/resume/Skills";
import ProjectApple from "../components/projects/projectApple";
import ProjectBitcoin from "../components/projects/projectBitcoin";
//import Contact from "../components/resume/Contact";

 const Home = () => {
    
    useEffect(()=>{
        //console.log("hello")
    }, [])

    return(
        <main>
            <Splash/>
            <div className="main_container">
                <Education/>
                <Experience/>
                <Skills/>
            </div>
            <div id="projects">
                <ProjectApple/>
                {/* <ProjectBitcoin/> */}
            </div>
            {/* <Contact/> */}
        </main>
    );
    

}

export default Home;